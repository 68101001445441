<template>
  <field
    name="phone"
    label="Celular"
    rules="required|phone"
    class="with-prefix"
    v-slot="{ label }"
  >
    <v-text-field
      type="tel"
      inputmode="numeric"
      autocomplete="tel"
      @change="onInput"
      :value="value"
      :label="label"
      hide-details="auto"
    />
  </field>
</template>

<script>
import { phoneMask } from "@/utils/masks.js";
import Field from "@/components/fields/Field";

export default {
  name: 'Phone',
  components: { Field },
  props: ['value'],
  data() {
    return {
      phoneMask,
    };
  },
  methods: {
    onInput(value) {
      this.$emit('input', value);
    }
  }
};
</script>

<style lang="scss"></style>
