<template>
  <div class="verify-code">
    <CodeInput
      :loading="false"
      :fields="6"
      class="flex"
      @change="onChange"
      @complete="onComplete"
    />
  </div>
</template>

<script>
import CodeInput from "vue-verification-code-input";

export default {
  name: "VerifyCode",
  components: { CodeInput },
  methods: {
    onChange(token) {
      this.$emit("input", token);
    },
    onComplete(token) {
      this.$emit("complete", token);
    },
  },
};
</script>

<style lang="scss">
:root .verify-code {
  .react-code-input-container,
  .react-code-input > input {
    width: 100% !important;
  }

  .react-code-input {
    @apply flex space-x-2;
  }

  .react-code-input > input {
    @apply flex-1 text-blue font-body font-bold bg-white border-0;
    border-bottom: 1.5px solid rgba(0, 19, 111, 0.25);
    border-radius: 0!important;
    height: 57px !important;
  }
}
</style>
