<template>
  <nivea-form :submit="login" class="login min-h-screen container py-16 lg:p-32 flex items-center justify-center">
    <div class="login-container shadow-login rounded max-w-full flex flex-grow-0 flex-col items-center bg-white py-10 px-5 sm:p-10">
      <logo class="mx-auto mb-1" />
      <h1>acesso à central de atendimento</h1>

      <div v-if="!submitted" class="login-step max-w-full flex-1 flex items-center flex-col justify-center">
        <phone v-model="phone" />
        <Button class="w-36 mt-10" type="submit" :loading="loading">Continuar</Button>
      </div>

      <div v-else class="login-step max-w-full flex-1 flex items-center flex-col justify-center">
        <p class="text-lg font-light text-blue my-6">
          Digite abaixo o código de 6 dígitos que foi enviado por SMS para você.
        </p>

        <verify-code v-model="token" />

        <div class="login-buttons max-w-full mx-auto flex flex-row items-center justify-between mt-12">
          <Button light ghost @click="back">Voltar</Button>
          <Button class="w-32" @click="confirm" :loading="loading">Entrar</Button>
        </div>
      </div>
    </div>
  </nivea-form>
</template>

<script>
import { mapState } from 'vuex';

import Logo from "@/components/Logo";
import Phone from "@/components/fields/Phone";
import VerifyCode from "@/components/VerifyCode";
import Button from "@/components/Button";
import NiveaForm from '@/components/NiveaForm';

export default {
  name: "ConsultantLogin",
  components: {
    NiveaForm,
    Logo,
    Phone,
    VerifyCode,
    Button,
  },
  data() {
    return {
      loading: false,
      submitted: false,
      phone: null,
      token: null
    };
  },
  computed: {
    ...mapState('consultant', ['authy_id']),
  },
  methods: {
    back() {
      this.submitted = false;
    },

    async login() {
      try {
        this.loading = true;
        await this.$store.dispatch('consultant/login', { phone: this.phone });
        this.submitted = true;
      } catch (e) {
        console.log('Error', e);
        this.$noty.error('Usuário ou senha inválidos.');
      }

      this.loading = false;
    },

    async confirm() {
      try {
        this.loading = true;

        await this.$store.dispatch('consultant/loginVerify', {
          authy_id: this.authy_id,
          token: this.token,
        });

        this.next();
      } catch (e) {
        console.log('Invalid!', e.response.data);
        this.$noty.error('Ops... código inválido.');
      }

      this.loading = false;
    },

    next() {
      this.$router.push({
        name: 'Consultant'
      });
    },
  },
};
</script>

<style lang="scss">
.login-container {
  width: 540px;
  height: 455px;
}

.login-step {
  width: 360px;

  .field {
    width: 100%;
  }
}

.login-buttons {
  width: 260px;

  @media (min-width: 400px) {
    width: 300px;
  }
}
</style>
